import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HelpfulHeroesTextField from "../../shared/HelpfulHeroesTextField";

const useStyles = makeStyles((theme) => ({
  additionalNotes: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "2rem",
  },
}));

const AdditionalNotes = ({ setDescriptionFormValue }) => {
  const classes = useStyles();
  return (
    <div className={classes.additionalNotes}>
      <Typography variant="title1">Additional Notes (optional)</Typography>
      <HelpfulHeroesTextField
        variant="outlined"
        className="large-input"
        onChange={(e) => setDescriptionFormValue("notes", e.target.value)}
        placeholder="We have a dog but don’t worry, it’s friendly!"
        sx={{ width: { md: "100%", xs: "100%" } }}
      />
    </div>
  );
};

AdditionalNotes.propTypes = {
  setDescriptionFormValue: PropTypes.func,
};

export default AdditionalNotes;
