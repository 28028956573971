import React from "react";
import { string, bool, func, object } from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const HelpfulHeroesTextField = ({
  variant = "outlined",
  value,
  handleChange,
  size,
  error = false,
  controller = false,
  control,
  name,
  label,
  className,
  disabled = false,
  defaultValue,
  ...props
}) => {
  if (!!controller) {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        disabled={disabled}
        render={({ field: { onChange, value } }) => (
          <TextField
            {...props}
            size={size}
            value={value}
            error={error}
            onChange={onChange}
            className={className}
            variant={variant}
            disabled={disabled}
            label={label}
          />
        )}
      />
    );
  }

  return (
    <TextField
      value={value}
      error={error}
      onChange={handleChange}
      className={className}
      variant={variant}
      label={label}
      disabled={disabled}
      size={size}
      {...props}
    />
  );
};

HelpfulHeroesTextField.propTypes = {
  variant: string,
  size: string,
  className: string,
  handleChange: func,
  error: bool,
  controller: bool,
  control: object,
  name: string,
  value: string,
  label: string,
  defaultValue: string,
};

export default HelpfulHeroesTextField;
