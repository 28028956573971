import { createTheme } from "@mui/material/styles";
import "./assets/fonts/Urbanist-Bold.ttf";
import "./assets/fonts/Urbanist-Medium.ttf";
import "./assets/fonts/Urbanist-SemiBold.ttf";

export const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#002653",
    },
    secondary: {
      main: "#535353",
    },
    success: {
      main: "#43c66b",
    },
    navy: {
      main: "#002653",
    },
    pink: {
      main: "#ff5559",
    },
    red: {
      main: "#B90E31",
      contrastText: "#ffff",
    },
    black: {
      main: "#000000",
    },
    grey: {
      main: "#acacac",
    },
    blue: {
      main: "#2b8ced",
    },
    offWhite: {
      main: "#fafafa",
    },
    backWhite: {
      main: "#e8e8e8",
    },
    actionButton: {
      main: "#2b8ced",
      contrastText: "#ffff",
    },
    lightGrey: {
      main: "#f1f1f1",
    },
    darkgray: {
      main: "#535353",
    },
    lightGreen: {
      main: "#43c66b",
    },
    green: {
      main: "#43c66b",
    },
  },
  typography: {
    fontFamily: "Urbanist-Bold",
    button: {
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "0.875rem",
      letterSpacing: "0.076rem",
      fontFamily: "Urbanist-SemiBold",
    },
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      fontFamily: "Urbanist-Bold",
      color: "#002653",
    },
    h2: {
      fontSize: "2.3rem",
      fontFamily: "Urbanist-Bold",
      color: "#002653",
    },
    h3: {
      fontSize: "1.7rem",
      fontFamily: "Urbanist-Bold",
      color: "#002653",
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: "Urbanist-Bold",
      fontWeight: "400",
    },
    h5: {
      fontSize: "1rem",
      lineHeight: "1rem",
      textAlign: "left",
      fontWeight: 600,
      fontFamily: "Urbanist-Bold",
      color: "#022752",
    },
    h6: {
      fontSize: "1rem",
      fontFamily: "Urbanist-Medium",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#535353",
    },
    h7: {
      fontSize: "1rem",
      fontFamily: "Urbanist-Medium",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "normal",
      lineHeight: 1.5,
      textAlign: "left",
      color: "#535353",
    },
    h8: {
      fontSize: "0.875rem",
      fontFamily: "Urbanist-Bold",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      letterSpacing: "normal",
      lineHeight: 1.21,
      textAlign: "center",
      color: "#022752",
    },
    h9: {
      fontSize: "1.125rem",
      fontFamily: "Urbanist-SemiBold",
      lineHeight: "3rem",
      fontWeight: 600,
      textAlign: "left",
      color: "white",
    },
    title1: {
      fontFamily: "Urbanist-Bold",
      fontSize: "1rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      marginBottom: "0.3rem",
      marginTop: "1rem",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#002653",
      alignItems: "center",
      display: "flex",
    },
    title2: {
      fontFamily: "Urbanist-Bold",
      fontSize: "1.875rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#002653",
    },
    title3: {
      fontFamily: "Urbanist-Bold",
      fontSize: "1.25rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#002653",
    },
    title4: {
      fontFamily: "Urbanist-Bold",
      fontSize: "1.5rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.92,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#002653",
    },
    title5: {
      fontFamily: "Urbanist-Bold",
      fontSize: "3.125rem",
      color: "#002653",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      textAlign: "left",
    },
    subtitle1: {
      fontFamily: "Urbanist-Bold",
      fontSize: "1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "0.92rem",
      letterSpacing: "0.24px",
      textAlign: "center",
      color: "#B90E31",
    },
    subtitle2: {
      fontFamily: "Urbanist-SemiBold",
      fontSize: "1rem",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 5.63,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#002653",
      marginTop: "-20px",
      marginBottom: "-20px",
    },
    body1: {
      fontSize: "1rem",
      fontFamily: "Urbanist-Medium",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#7e7e7e",
    },
    body2: {
      fontSize: "0.875rem",
      fontFamily: "Urbanist-Medium",
      fontStretch: "normal",
      fontWeight: 500,
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#7b7b7b",
    },
    body3: {
      fontSize: "0.875rem",
      lineHeight: "0.688rem",
      color: "#2b8ced",
      fontFamily: "Urbanist-SemiBold",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
    },
    body4: {
      fontFamily: "Urbanist-Medium",
      fontSize: "0.75rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.92,
      letterSpacing: 0.24,
      textAlign: "left",
      color: "#acacac",
    },
    error1: {
      fontSize: "0.875rem",
      fontFamily: "Urbanist-Medium",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "left",
      color: "red",
    },
    success1: {
      fontSize: "0.875rem",
      fontFamily: "Urbanist-Medium",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#43c66b",
    },
    inherit: {
      fontSize: "inherit",
      fontWeight: "inherit",
    },
    overline: {
      fontSize: "1rem",
    },
    caption: {
      fontSize: "1.1rem",
    },
  },
});

export const overridesTheme = createTheme(theme, {
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: "1.8rem",
          paddingRight: "1.8rem",
          paddingBottom: "0.8rem",
          paddingTop: "0.8rem",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "0.8rem",
            paddingRight: "0.8rem",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "navy",
          cursor: "pointer",
          textDecoration: "underline navy",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: "7.5rem",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#fff",
          width: "100%",
          boxShadow: "none",
          paddingLeft: "10rem",
          paddingRight: "10rem",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "2rem",
            paddingRight: "0rem",
            height: "6rem",
          },
          [theme.breakpoints.down("md")]: {
            paddingLeft: "2rem",
            paddingRight: "0.5rem",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
          marginLeft: "0",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          marginTop: "14px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          width: "12.125rem",
          height: "13.8rem",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#fafafa",
          },
          [theme.breakpoints.down("sm")]: {
            width: "10rem",
            height: "11.25rem",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "77.1px",
          height: "77.1px",
          backgroundColor: "#fafafa",
          "&:hover": {
            backgroundColor: "#ffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "white",
          fontSize: "1rem",
          backgroundColor: "#fc454a",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: "0.5rem",
          borderRadius: "5px",
          boxShadow: "none",
          backgroundColor: "#fafafa",
          "&:before": {
            backgroundColor: "#fff",
          },
          ".Mui-expanded": {
            backgroundColor: "#fff",
          },
          "-region": {
            backgroundColor: "#fff",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: "2rem !important",
          width: "100%",
          borderRadius: "5px",
          minHeight: "4rem",
          padding: "0px",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "0.5rem !important",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: 0,
          borderRadius: "5px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: "3rem",
          width: "100%",
          fontFamily: "Urbanist-Medium",
          justifyContent: "center",
        },
      },
    },
  },
});
