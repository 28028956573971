import React from "react";
// import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import TopBar from "../../components/shared/TopBar";
import Location from "../../components/NewProject/Location";
import Description from "../../components/NewProject/Description";
import Estimate from "../../components/NewProject/Estimate";
import ContactInformation from "../../components/NewProject/ContactInformation";
import PaymentInformation from "../../components/NewProject/PaymentInformation";
import useSession from "../../hooks/useSession";
import BottomBar from "../../components/shared/BottomBar";
import { useQueryParam, StringParam } from "use-query-params";
import { useAllProjectTypes } from "../../hooks/useProject";
import { useDispatch } from "react-redux";
import { getAllProjectTypes } from "../../redux/actions/projectActions";

const useStyles = makeStyles((theme) => ({
  createProject: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    marginBottom: "3rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
  createProjectHeader: {
    display: "flex",
    marginBottom: "1.1rem",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "0",
    },
  },
  projectDescription: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

const CreateProject = ({ props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel1");
  const { authenticated, user } = useSession();
  const { handleSubmit, setValue } = useForm();
  const [showVerification, setShowVerification] = React.useState(false);
  const dispatch = useDispatch();
  const [service, setService] = useQueryParam("service", StringParam);
  const { allProjectTypes } = useAllProjectTypes();
  const [selectedService, setSelectedService] = React.useState();
  const [isOpen, setIsOpen] = React.useState(true);

  if (!service) {
    setService(localStorage.getItem("serviceLocal"));
  } else {
    localStorage.removeItem("service");
  }
  if (isOpen) {
    dispatch(getAllProjectTypes());
    setIsOpen(false);
  }

  React.useEffect(() => {
    setSelectedService(
      allProjectTypes?.filter((item) => item.name === service)
    );
  }, [allProjectTypes, dispatch, service]);

  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const showContactInformation =
    !authenticated || showVerification || (authenticated && !user?.verifiedAt);

  return (
    <>
      <TopBar />
      <div className={classes.createProject}>
        <IconButton
          sx={{ paddingLeft: "0", alignSelf: "start", marginBottom: "1rem" }}
          onClick={() => navigate("/")}
          marginBottom="23px"
        >
          <ArrowBackIcon sx={{ marginRight: "0.9rem" }} color="red" />
          <Typography variant="body3" color="#B90E31">
            Back
          </Typography>
        </IconButton>
        {selectedService && (
          <>
            <div className={classes.createProjectHeader}>
              <>
                <Typography variant="h2">{selectedService[0]?.name}</Typography>
                <Typography
                  sx={{
                    mt: { sm: 0, xs: "0.6rem" },
                    mb: { sm: 0, xs: "1.1rem" },
                  }}
                  variant="body3"
                >
                  Avg. Project ${selectedService[0]?.lowestAvgCost} - $
                  {selectedService[0]?.highestAvgCost}
                </Typography>
              </>
            </div>
            <div className={classes.projectDescription}>
              <Typography variant="title1">
                {selectedService[0]?.shortDescription}
              </Typography>
            </div>
          </>
        )}
        <Location
          expanded={expanded}
          setExpanded={setExpanded}
          handleChange={handleChange}
          setGlobalValue={setValue}
        />
        <Description
          expanded={expanded}
          setExpanded={setExpanded}
          handleChange={handleChange}
          setGlobalValue={setValue}
        />
        {showContactInformation && (
          <ContactInformation
            expanded={expanded}
            setExpanded={setExpanded}
            handleChange={handleChange}
            showVerification={showVerification}
            setShowVerification={setShowVerification}
          />
        )}
        <PaymentInformation
          expanded={expanded}
          setExpanded={setExpanded}
          handleChange={handleChange}
          setGlobalValue={setValue}
        />
        <Estimate
          expanded={expanded}
          setExpanded={setExpanded}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          selectedService={selectedService}
        />
      </div>
      <BottomBar />
    </>
  );
};

export default CreateProject;
