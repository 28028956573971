import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import TopBar from "../../components/shared/TopBar";
import CurrencyInput from "react-currency-input";
import addingTip from "../../assets/images/addTip.svg";
import { addTip } from "../../redux/actions/projectActions";
import { useDispatch } from "react-redux";
import {
  FULFILLED,
  REJECTED,
} from "../../redux/constants/actionStatusConstants";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    display: "flex",
    width: 352,
    justifyContent: "center",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  input: {
    border: "none",
    fontFamily: "Urbanist-Medium",
    fontSize: "4.5rem",
    color: "#002653",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "5.625rem",
    letterSpacing: "normal",
    width: 220,
    borderColor: "navy",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  box: {
    padding: "11px 15px 7px",
    borderRadius: 5,
    border: "solid 1px #d0d0d0",
    width: 352,
    justifyContent: "center",
    alignSelf: "center",
    fontFamily: "Urbanist-Medium",
    fontSize: "0.875rem",
    color: "#acacac",
    alignItems: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const AddTip = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tip, setTip] = useState("0.00");
  const dispatch = useDispatch();

  const pay = useMemo(() => {
    const parsed = parseFloat(tip);
    const result = ((parsed + 0.3) * 100) / 97.1;
    return result.toFixed(2);
  }, [tip]);

  const handleChange = (event, maskedvalue, floatvalue) => {
    setTip(maskedvalue);
  };

  const handleClick = async () => {
    const response = await dispatch(
      addTip({ jobId: parseInt(id), tip: parseFloat(pay) })
    );

    if (response.meta.requestStatus === FULFILLED) {
      toast.success(
        <Typography color="success">
          Your tip has been sent successfully! Thanks
        </Typography>,
        {
          position: "top-center",
        }
      );
      navigate("/");
    }
    if (response.meta.requestStatus === REJECTED) {
      toast.error(
        <Typography color="error">
          There has been an error adding the tip. Please try again or contact
          support.
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };

  return (
    <div>
      <TopBar />
      <div className={classes.body}>
        <img alt="" src={addingTip} style={{ width: "380px" }} />
        <div className={classes.text}>
          <Typography variant="h6">
            Your project is complete! Say thanks and add a tip for your
            firefighter.
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="title5" sx={{ fontSize: 42 }}>
            $
          </Typography>
          <CurrencyInput
            value={tip}
            onChangeEvent={handleChange}
            className={classes.input}
            thousandSeparator=""
          />
        </div>
        <Typography variant="subtitle2" className={classes.bodyWidth}>
          Add a tip
        </Typography>
        <Typography sx={{ fontSize: "2.5rem", color: "#acacac" }}>
          {pay}
        </Typography>
        <Typography
          sx={{ fontSize: "1.125rem", color: "#acacac", fontWeight: "bold" }}
        >
          Total
        </Typography>
        <Button
          sx={{
            backgroundColor: "#2B8CED",
            color: "white",
            width: { sm: 259, xs: "90%" },
            height: 46,
            marginTop: "1.25rem",
            marginBottom: 15,
          }}
          onClick={() => handleClick()}
        >
          Add Tip
        </Button>
        <Typography
          sx={{ textAlign: "center", marginBottom: "2rem" }}
          className={classes.box}
        >
          So that Firefighters receive 100% of your tip Helpful Heroes charges a
          +4% Processing Fee.
        </Typography>
      </div>
    </div>
  );
};
export default AddTip;
