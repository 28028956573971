import React from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Graph from "../../assets/images/graph.svg";
import "./index.scss";

const useStyle = makeStyles((theme) => ({
  secondColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "2.375rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "2.375rem",
    },
  },
  carousel: {
    height: "20rem",
    width: "100%",
    objectFit: "fit",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  estimatePrices: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
}));

const ProjectDetailSecondColumn = ({ project }) => {
  const classes = useStyle();
  return (
    <div className={classes.secondColumn}>
      <Carousel showArrows showStatus={false} showThumbs={false}>
        {project.images?.map((image) => (
          <img
            alt={image.id}
            src={image.data}
            key={image.id}
            className={classes.carousel}
          />
        ))}
      </Carousel>
      <div
        style={{
          marginTop: "47px",
          display: "flex",
          flexDirection: "column",
          marginLeft: { sm: "1.8rem", xs: "0rem" },
        }}
      >
        <Typography variant="title4">Details</Typography>
        <Typography variant="subtitle2">Project Description</Typography>
        <Typography variant="h7">{project?.description}</Typography>
        <Divider sx={{ marginTop: "1.875rem" }} />
        {project?.specialEquipment === "yes" && (
          <>
            <Typography variant="subtitle2">Special Equipment</Typography>
            <Typography variant="h7">{project.equipmentDetails}</Typography>
            {project.notes && <Divider sx={{ marginTop: "1.875rem" }} />}
          </>
        )}
        {project.notes && (
          <>
            <Typography variant="subtitle2">Additional information</Typography>
            <Typography variant="h7">{project.notes}</Typography>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "4.188rem",
          marginLeft: { sm: "1.8rem", xs: 0 },
        }}
      >
        <Typography variant="title4">Estimate</Typography>
        <div className={classes.estimatePrices}>
          <img
            alt=""
            src={Graph}
            style={{ position: "absolute", width: "100%" }}
          />
          <div>
            <Typography
              sx={{ color: "navy !important", mb: "0.5rem" }}
              variant="subtitle1"
            >
              Low
            </Typography>
            <Typography variant="body3">
              $ {project?.service?.lowestCost}
            </Typography>
          </div>
          <Divider
            sx={{
              height: "11.9rem",
              borderStyle: "dashed",
            }}
            orientation="vertical"
          />
          <div>
            <Typography
              sx={{ color: "navy !important", mb: "0.5rem" }}
              variant="subtitle1"
            >
              Most Spend
            </Typography>
            <Typography
              sx={{
                color: "lightGreen !important",
                fontFamily: "Urbanist-Medium",
                fontWeight: "100",
              }}
              variant="subtitle1"
            >
              $ {project?.service?.lowestAvgCost} - ${" "}
              {project?.service?.highestAvgCost}
            </Typography>
          </div>
          <Divider
            sx={{ height: "11.9rem", borderStyle: "dashed" }}
            orientation="vertical"
          />
          <div>
            <Typography
              sx={{
                color: "navy !important",
                mb: "0.5rem",
              }}
              variant="subtitle1"
            >
              High
            </Typography>
            <Typography
              sx={{
                fontFamily: "Urbanist-Medium",
                fontWeight: "100",
              }}
              variant="subtitle1"
            >
              $ {project?.service?.highestCost}
            </Typography>
          </div>
        </div>
        <Typography
          sx={{
            color: "#43c66b !important",
            fontFamily: "Urbanist-Medium",
            fontWeight: "100",
            marginTop: "1rem",
            width: "100%",
          }}
          variant="h1"
        >
          $ {project?.service?.avgCost}
        </Typography>
        <Typography
          sx={{
            color: "#002653 !important",
            mt: "0.5rem",
            mb: "1.5rem",
            width: "100%",
          }}
          variant="subtitle1"
        >
          Helpful Heroes Average{" "}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center !important",
            color: "#535353",
            width: "100%",
          }}
        >
          We provide customers with a project estimate based on trends in your
          area each service. Once the project is completed, you will charge the
          customer a fair price within the price range.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center !important",
            color: "#535353",
            marginTop: "1.5rem",
            mb: "5rem",
          }}
        >
          <b
            style={{ color: "#2b8ced", cursor: "pointer" }}
            onClick={() => window.open("https://hidrent.com/faq")}
          >
            Check out our FAQs
          </b>{" "}
          to learn about the payment process.
        </Typography>
      </div>
    </div>
  );
};

export default ProjectDetailSecondColumn;
