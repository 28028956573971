import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import TopBar from "../../components/shared/TopBar";
import { Typography, Button, Tab, Tabs, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { getJobs } from "../../redux/actions/userActions";
import useJobs from "../../hooks/useJobs";
import ProjectCard from "./ProjectCard/index";
import useStatus from "../../hooks/useStatus";
import {
  FULFILLED,
  PENDING,
} from "../../redux/constants/actionStatusConstants";
import BottomBar from "../../components/shared/BottomBar";
import { resetProjectDetails } from "../../redux/actions/projectActions";

const useStyles = makeStyles((theme) => ({
  myProjects: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "2rem",
    alignItems: "flex-start",
  },
  tabPanel: {
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyProjects = () => {
  const [value, setValue] = React.useState(0);
  const { jobs } = useJobs();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useStatus(getJobs);

  if (status === FULFILLED && jobs?.length === 0) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetProjectDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === PENDING) {
    return null;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreate = () => {
    navigate("/");
  };

  return (
    <div>
      <TopBar />
      <div className={classes.myProjects}>
        <Typography variant="title2">My Projects</Typography>
        <Button
          variant="contained"
          color="navy"
          sx={{ color: "white" }}
          onClick={() => handleCreate()}
        >
          Create New Project
        </Button>
      </div>
      <div className={classes.tabPanel}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ borderBottom: "1px solid #d0d0d0" }}
        >
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Previous" {...a11yProps(1)} />
        </Tabs>
      </div>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <div className={classes.cardsContainer}>
          {jobs
            ?.filter(
              (item) =>
                item.status === "assigned" ||
                item.status === "pending" ||
                item.status === "unassigned"
            )
            ?.map((project) => (
              <ProjectCard
                onClick={() => navigate(`/project-detail/${project.id}`)}
                project={project}
                key={project.id}
              />
            ))}
        </div>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <div className={classes.cardsContainer}>
          {jobs
            ?.filter(
              (item) =>
                item.status === "completed" || item.status === "cancelled"
            )
            ?.map((project) => (
              <ProjectCard
                // Business Rule: Not clickable when job is completed
                // onClick={() => navigate(`/project-detail/${project.id}`)}
                project={project}
                key={project.id}
              />
            ))}
        </div>
      </TabPanel>
      <BottomBar />
    </div>
  );
};

export default MyProjects;
