import React from "react";
import { Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const useStyles = makeStyles((theme) => ({
  isMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  icons: {
    marginBottom: 125,
    marginTop: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 36,
    },
  },
  shareBar: {
    marginTop: "3rem",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "13.25rem",
    alignSelf: "center",
    alignItems: "baseline",
  },
}));

const ShareBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.shareBar}>
      <Typography variant="body3" sx={{ color: "#acacac" }}>
        Hey, we’re new. Feel free to share!
      </Typography>
      <div className={classes.icons}>
        <InstagramIcon sx={{ color: "#c1c1c1" }} />
        <FacebookIcon sx={{ ml: "1.25rem", mr: "1.25rem", color: "#c1c1c1" }} />
        <TwitterIcon sx={{ color: "#c1c1c1" }} />
      </div>
      <div className={classes.isMobile}>
        <Typography variant="body3" sx={{ color: "#acacac" }}>
          © 2024 Helpful Heroes Inc.. All Rights Reserved.
        </Typography>
        <div className={classes.row}>
          <Typography
            variant="body3"
            sx={{
              textDecoration: "underline",
              color: "#acacac",
              marginTop: "7px",
              marginBottom: "30px",
            }}
            onClick={() => window.open("https://hidrent.com/privacy-policy")}
          >
            Privacy Policy
          </Typography>
          <Divider
            orientation="vertical"
            sx={{
              height: "0.875rem",
              border: "0.063rem solid #acacac",
              marginTop: "0.313rem",
            }}
          />
          <Typography
            variant="body3"
            sx={{
              textDecoration: "underline",
              color: "#acacac",
              marginTop: "7px",
              marginBottom: "30px",
            }}
            onClick={() => window.open("https://hidrent.com/terms-of-service")}
          >
            Terms of Service
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ShareBar;
