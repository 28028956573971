import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import HelpfulHeroesTextField from "../../shared/HelpfulHeroesTextField";

const useStyles = makeStyles((theme) => ({
  projectInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
}));

const ProjectDescription = ({ control, errors }) => {
  const classes = useStyles();
  return (
    <div className={classes.projectInformationContainer}>
      <HelpfulHeroesTextField
        name="title"
        control={control}
        controller
        sx={{
          width: { xs: "100%", md: "100%" },
        }}
        placeholder="Name your project"
        error={!!errors?.title}
        helperText={errors?.title?.message}
      />
      <Typography variant="title1">Project Description</Typography>
      <HelpfulHeroesTextField
        multiline
        controller
        name="description"
        control={control}
        placeholder="Detailed information will make it more likely for your job to be accepted and complete quickly. "
        sx={{
          width: "100%",
          fontFamily: "Urbanist-Medium",
          fontSize: "0.875rem",
        }}
        error={!!errors?.description}
        helperText={
          errors?.description?.message ||
          "*Please note that plumbing, electrical and HVAC are best handled by professionals"
        }
      />
    </div>
  );
};

export default ProjectDescription;
