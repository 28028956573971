import React from "react";
import { Button } from "@mui/material";

const HelpfulHeroesButton = ({
  state,
  setState,
  name,
  setDescriptionFormValue,
  control,
  children,
}) => {
  const handleClick = () => {
    setState(`${name}`);

    if (setDescriptionFormValue) {
      setDescriptionFormValue(`${state}`, `${name}`);
    }
  };
  return (
    <Button
      variant="contained"
      color={(state === name && "navy") || "lightGrey"}
      style={{
        color: (state === name && "white") || "navy",
        margin: 8,
      }}
      name={name}
      control={control}
      controller
      onClick={() => handleClick()}
    >
      {children}
    </Button>
  );
};

export default HelpfulHeroesButton;
