/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Inputmask from "inputmask";
import HelpfulHeroesTextField from "../HelpfulHeroesTextField";

const HelpfulHeroesPhoneInput = ({
  errors,
  control,
  id,
  placeholder,
  className,
  error,
  helperText,
  controller,
  name,
  ...props
}) => {
  React.useEffect(() => {
    Inputmask({ mask: "(999) 999-9999" }).mask(document.getElementById(id));
  }, []);

  return (
    <HelpfulHeroesTextField
      name={name}
      control={control}
      controller={controller}
      id={id}
      placeholder={placeholder}
      className={className}
      error={error}
      helperText={helperText}
      required
      {...props}
    />
  );
};

export default HelpfulHeroesPhoneInput;
